<template>
  <div>
    <tafel-system />
  </div>
</template>

<script>
import TafelSystem from "../components/TafelSystem.vue";

export default {
  name: "Produktionsplanung",
  components: {
    TafelSystem
  }
};
</script>
